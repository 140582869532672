/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(111, 111, 111);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
